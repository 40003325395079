import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=25a34099&scoped=true"
import script from "./TheSidebar.vue?vue&type=script&lang=js"
export * from "./TheSidebar.vue?vue&type=script&lang=js"
import style0 from "./TheSidebar.vue?vue&type=style&index=0&id=25a34099&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a34099",
  null
  
)

export default component.exports